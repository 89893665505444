import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/remoteservices/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";

import img1 from "../../images/remoteservices/1.png";
const images = [
    {
        original: img1,
    },
];
const RemoteServices = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Remote Services"}
            navSize={"h-96"}
            titleStyles={"xl:mt-14 lg:mt-22 md:mt-20 sm:mt-24 mt-20"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Remote Services
                </title>
                <meta
                    name="description"
                    content="On-site remote power generation services to remote communities across the Top End and Central Australia. Servicing the whole of the Northern Territory."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />

            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white py-8"}>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        At E-P-G Solutions, we’re not scared of hard work, so we offer
                        on-site services to every remote community in the Northern
                        Territory. We’re not fussy either so we are able to look after
                        ourselves while on-site.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Our team are experienced in working in the challenging Territory
                        conditions and understand the importance of getting the job done
                        properly while always maintaining our own health and safety.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We have purpose-built vehicles for remote work and carry back-up
                        supplies if the job takes longer than expected. We respect the
                        cultural practices and traditions of the communities we stay in and
                        aim to make as little impact as possible. In fact, you probably
                        won’t even know we were there.
                    </p>
                </div>
                <iframe
                    className={"border-0 pt-5 bg-epg-white w-full h-96"}
                    loading="lazy"
                    allowFullScreen
                    src="https://maps.google.com/maps?q=Northern%20Territory%2C%20Australia&amp;t=m&amp;z=4&amp;output=embed&amp;iwloc=near"
                    title="Northern Territory, Australia"
                />
                <div className={"bg-epg-white pt-5"}>
                    <Gallery noPlay images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default RemoteServices;
